import {
  useFeatureFlagBoolean,
  useFeatureFlagClient,
} from '@customer-frontend/feature-flags';
import {
  ProblemType,
  SoftwareProfileHomeQuery,
} from '@customer-frontend/graphql-types';
import {
  filterConsultationCards,
  filterQuizCards,
  useProfileCards as useSharedProfileCards,
} from '@customer-frontend/services';
import { isSkinProblemType } from '@customer-frontend/utils';
import { Variant } from 'components/skin-tracker-profile-card';
import { useCheckInQuiz } from './check-in/use-check-in-quiz-config';
import {
  ConsultationProfileCardProps,
  getConsultationProfileCard,
} from './consultation-profile-card';
import { getEducationCard } from './education-card';
import { getSkinOnboardingCard } from './onboarding/skin-onboarding-card';
import { useSkinOnboardingQuizConfig } from './onboarding/use-skin-onboarding-quiz-config';
import { SkinOnboardingQuizCardPosition } from './onboarding/utils';
import { getSkinSideEffectsCard } from './skin-side-effects-card';
import { getSkinTrackerProfileCard } from './skin-tracker-profile-card';
import { getSoftwareInsidersCard } from './software-insiders-card';
import { getStartQuizCard, StartQuizCardProps } from './start-quiz-card';
import { useGetTreatmentStartedCard } from './treatment-started-date/use-get-treatment-started-card';
import { completedStatuses, sortProfileTreatments } from './utils';
import { QuizCardProps, getQuizCard } from './quiz-card';
import { useMemo } from 'react';

export type CardKey =
  | `consultation:${ProblemType}`
  | `quiz:${ProblemType}`
  | `startQuiz:${ProblemType}`
  | 'skinTrackerProfileCard'
  | 'skinSideEffectsCard'
  | 'skinOnboardingQuizCard'
  | 'skinCheckInQuizCard'
  | 'treatmentStartedDateCard'
  | 'softwareInsidersCard'
  | 'skinCheckInResultsQuizCard'
  | 'softwareEducationCard';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Card<TProps = any> = {
  CardComponent: React.FC<TProps>;
  props: TProps;
  key: CardKey;
};

const useQuizAndConsultationCards = (
  data?: SoftwareProfileHomeQuery,
): {
  consultationCards: Card<ConsultationProfileCardProps>[];
  quizCards: Card<QuizCardProps>[];
  startQuizCards: Card<StartQuizCardProps>[];
} => {
  const profileCards = useSharedProfileCards(data);

  return useMemo(() => {
    const cards = profileCards.sort(sortProfileTreatments);
    const consultationCards = filterConsultationCards(cards);
    const quizCards = filterQuizCards(cards);
    const hasSkinConsult = consultationCards.some(({ consultation }) =>
      isSkinProblemType(consultation.type),
    );
    const hasHairConsult = consultationCards.some(
      ({ consultation }) => consultation.type === 'HAIR',
    );
    const hasSkinQuiz = quizCards.some(({ quizApplication }) =>
      isSkinProblemType(quizApplication.problemType),
    );
    const hasHairQuiz = quizCards.some(
      ({ quizApplication }) => quizApplication.problemType === 'HAIR',
    );

    return {
      consultationCards: consultationCards.map(({ consultation }) =>
        getConsultationProfileCard({
          props: { consultation, userEmail: data?.profile?.email },
          key: `consultation:${consultation.type}`,
        }),
      ),

      quizCards: quizCards.map(({ quizApplication }) =>
        getQuizCard({
          props: { quizApplication },
          key: `quiz:${quizApplication.problemType}`,
        }),
      ),

      startQuizCards: [
        ...(!hasSkinConsult && !hasSkinQuiz
          ? [
              getStartQuizCard({
                props: { problemType: 'SKIN_GENERAL' },
                key: 'startQuiz:SKIN_GENERAL',
              }),
            ]
          : []),

        ...(!hasHairConsult && !hasHairQuiz
          ? [
              getStartQuizCard({
                props: { problemType: 'HAIR' },
                key: 'startQuiz:HAIR',
              }),
            ]
          : []),
      ],
    };
  }, [profileCards, data?.profile?.email]);
};

export const useProfileCards = ({
  data,
}: {
  data?: SoftwareProfileHomeQuery;
}): Card[] => {
  const ffClient = useFeatureFlagClient();
  const getIsEducationEnabled = (): boolean =>
    ffClient.getMultivariate('SOFTWARE_EDUCATION_WIDGET') === 'variation';

  const isInsidersCardEnabled = useFeatureFlagBoolean(
    'SOFTWARE_INSIDERS_WIDGET',
  );

  const skinTreatment = data?.profile?.consultations.find(
    (c) => isSkinProblemType(c.type) && c.treatment,
  )?.treatment;

  const treatmentStartedDateCard = useGetTreatmentStartedCard(skinTreatment);
  const { consultationCards, quizCards, startQuizCards } =
    useQuizAndConsultationCards(data);
  const skinTrackerProfileCard = getSkinTrackerProfileCard({
    props: {
      progressEntries: data?.progressEntries,
      variant: Variant.TreatmentPage,
    },
    key: 'skinTrackerProfileCard',
  });
  const skinSideEffectsCard = getSkinSideEffectsCard({
    key: 'skinSideEffectsCard',
  });
  const softwareInsidersCard = getSoftwareInsidersCard({
    key: 'softwareInsidersCard',
  });
  const educationCard = getEducationCard({
    key: 'softwareEducationCard',
  });
  const skinConsultCard = consultationCards.find((c) =>
    isSkinProblemType(c.props.consultation.type),
  );
  const { shouldShowSkinOnboardingQuiz, skinOnboardingQuizPosition, ctaText } =
    useSkinOnboardingQuizConfig({
      consultation: skinConsultCard?.props.consultation,
      trackerQuizApplications: data?.profile?.trackerQuizApplications,
    });

  const skinOnboardingQuizCard = getSkinOnboardingCard({
    props: { ctaText },
    key: 'skinOnboardingQuizCard',
  });

  const skinCheckInQuizCard = useCheckInQuiz({
    consultation: skinConsultCard?.props.consultation,
    trackerQuizApplications: data?.profile?.trackerQuizApplications,
    customerAttributes: data?.customerAttributes,
  });

  const hasPaidSkin = data?.profile?.consultations.some(
    (consultation) =>
      isSkinProblemType(consultation.type) &&
      completedStatuses.includes(consultation.status),
  );

  // START: Adding cards to home page
  if (!data) {
    return [];
  }

  if (!consultationCards.length) {
    return [...quizCards, ...startQuizCards];
  }

  const cards: Card[] = [];

  if (skinCheckInQuizCard) {
    cards.push(skinCheckInQuizCard);
  }
  if (treatmentStartedDateCard) {
    cards.push(treatmentStartedDateCard);
  }

  cards.push(...consultationCards, ...quizCards, ...startQuizCards);

  if (skinConsultCard && shouldShowSkinOnboardingQuiz) {
    const skinConsultationCardIndex = cards.indexOf(skinConsultCard);
    switch (skinOnboardingQuizPosition) {
      case SkinOnboardingQuizCardPosition.ABOVE_TREATMENT:
        cards.splice(skinConsultationCardIndex, 0, skinOnboardingQuizCard);
        break;
      case SkinOnboardingQuizCardPosition.BELOW_TREATMENT:
        cards.splice(skinConsultationCardIndex + 1, 0, skinOnboardingQuizCard);
        break;
    }
  }

  const hasSkinConsult = data?.profile?.consultations.some((c) =>
    isSkinProblemType(c.type),
  );

  if (hasSkinConsult) {
    cards.push(skinTrackerProfileCard);
  }

  if (hasPaidSkin && getIsEducationEnabled() === true) {
    cards.push(educationCard);
  }

  if (hasPaidSkin) {
    if (isInsidersCardEnabled) {
      cards.push(softwareInsidersCard);
    }
    cards.push(skinSideEffectsCard);
  }

  return cards;
};
