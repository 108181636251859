import { gql } from '@apollo/client';
import { OfferingHighlightsSequenceFragment } from '@customer-frontend/graphql-types';
import { Modal, Typography, TypographySize } from '@eucalyptusvc/design-system';
import { ReactComponent as Snowflake } from '../../assets/snowflake.svg';
import { ReactComponent as DollarCoin } from '../../assets/dollar-coin.svg';
import { ReactComponent as DeleteCross } from '../../assets/delete-cross.svg';
import { ReactComponent as InfoCircle } from '../../assets/info-circle.svg';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { getConfig } from '@customer-frontend/config';
import { useState } from 'react';
import { useEnvironment } from '@customer-frontend/environment';

function OfferingHighlights({
  sequence,
  styles,
}: {
  sequence: OfferingHighlightsSequenceFragment | null;
  styles: {
    textSize: TypographySize;
    iconDimension: number;
    textClassName: string;
  };
}) {
  const config = getConfig();
  const [modalContent, setModalContent] = useState<{
    title: string;
    description: React.ReactElement;
  }>();
  const environment = useEnvironment();

  let containsRefrigeratedContents = false;
  if (sequence?.__typename === 'PrescribableSequence') {
    containsRefrigeratedContents =
      sequence.containsRefrigeratedContents ?? false;
  }

  const iconClassNames = clsx('flex-shrink-0', {
    'mt-1.5': styles.textSize === 'large-paragraph',
    'mt-1': styles.textSize === 'paragraph',
  });

  return (
    <div className={clsx('space-y-4', styles.textClassName)}>
      <Modal
        size="sm"
        title={
          <Typography size="medium-paragraph" isBold>
            {modalContent?.title}
          </Typography>
        }
        isOpen={modalContent !== undefined}
        onClose={() => setModalContent(undefined)}
      >
        {modalContent?.description}
      </Modal>

      {containsRefrigeratedContents && (
        <div className="flex flex-row space-x-3 items-start">
          <Snowflake
            className={iconClassNames}
            width={styles.iconDimension}
            height={styles.iconDimension}
          />
          <Typography size={styles.textSize}>
            <FormattedMessage
              defaultMessage="Medication requires cold storage."
              description="Review treatment page"
            />
          </Typography>
        </div>
      )}

      {config.purchaseActivationFlow?.moneyBackGuaranteeDays && (
        <div className="flex flex-row space-x-3 items-start">
          <DollarCoin
            className={iconClassNames}
            width={styles.iconDimension}
            height={styles.iconDimension}
          />

          <Typography size={styles.textSize}>
            <FormattedMessage
              defaultMessage="{days}-day money-back guarantee."
              description="Review treatment page"
              values={{
                days: config.purchaseActivationFlow.moneyBackGuaranteeDays,
              }}
            />
          </Typography>

          <button
            className={clsx(
              'p-0.5 ml-1',
              styles.textClassName,
              styles.textSize === 'large-paragraph' ? 'mt-1.5' : 'mt-0.5',
            )}
            onClick={() => {
              setModalContent({
                title: '30-day money-back guarantee',
                description: (
                  <Typography size="paragraph">
                    <FormattedMessage
                      defaultMessage="Our money-back guarantee applies to plan subscriptions only. Add-ons are excluded. Read Terms and Conditions <a>here</a>."
                      values={{
                        a: (chunks) => (
                          <a
                            href={
                              environment.landingPageUrl +
                              config.urlPaths.termsAndConditions
                            }
                            className="text-link"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                ),
              });
            }}
          >
            <InfoCircle
              width={14}
              height={14}
              fill="currentColor"
              className="-mb-0.5"
            />
          </button>
        </div>
      )}
      <div className="flex flex-row space-x-3 items-start">
        <DeleteCross
          className={iconClassNames}
          width={styles.iconDimension}
          height={styles.iconDimension}
        />
        <Typography size={styles.textSize}>
          <FormattedMessage
            defaultMessage="Pause or cancel your treatment anytime."
            description="Review treatment page"
          />
        </Typography>
      </div>
    </div>
  );
}

OfferingHighlights.sequenceFragment = gql`
  fragment OfferingHighlightsSequence on Sequence {
    id
    ... on PrescribableSequence {
      containsRefrigeratedContents
    }
  }
`;

export { OfferingHighlights };
